import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import CustomerAddress from "./CustomerAddress";
import { customNotify } from "../../../Shared-Components/Notification";
import { useGetCustomerSegments } from "../../../../queries/userSegments";
import { Segments, userSegments } from "../../../../constants/lib";
export var OrderType;
(function (OrderType) {
    OrderType["pickup"] = "pickup";
    OrderType["delivery"] = "delivery";
    OrderType["drive_thru"] = "drive_thru";
})(OrderType || (OrderType = {}));
var CustomerInformation = function (props) {
    var _a, _b;
    var CustomerInfo = props.CustomerInfo, area = props.area, country = props.country, government = props.government, address = props.address, orderType = props.orderType, district_ar_name = props.district_ar_name, district_en_name = props.district_en_name, sub_district_ar_name = props.sub_district_ar_name, sub_district_en_name = props.sub_district_en_name, customer_country = props.customer_country, comment = props.comment, order = props.order, city_en_name = props.city_en_name, isMobile = props.isMobile, lat = props.lat, lng = props.lng;
    var data = useGetCustomerSegments(CustomerInfo.customer.id).data;
    var Av = React.useMemo(function () {
        return CustomerInfo.customer.name &&
            CustomerInfo.customer.name
                .split(/\s/)
                .reduce(function (response, word) { return response + word.slice(0, 1); }, "");
    }, [CustomerInfo]);
    var customerProfileURL = React.useCallback(function () {
        try {
            var url = new URL("" + process.env.WATCH_TOWER_URL);
            return url.origin + "/#/user-profile/" + CustomerInfo.customer.phone_number + "?order_id=" + order.id + "&order_type=" + order.order_type;
        }
        catch (error) {
            customNotify.error("Invalid watcher URL");
        }
    }, [CustomerInfo, order]);
    return (React.createElement("div", { className: "" + (isMobile ? styles.mobileWrapper : styles.wrapper) },
        React.createElement("div", { className: styles.customerInformationWrapper },
            React.createElement("span", { className: styles.title }, "User Information"),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.contentWrapper, style: { alignItems: "center" } },
                    Av ? (React.createElement("div", { className: styles.avatarWrapper }, Av)) : (React.createElement("div", { className: styles.avatarWrapper })),
                    React.createElement("div", { className: styles.contactWrapper, style: area && government && country
                            ? { height: "16.4rem" }
                            : {
                                height: "14.4rem",
                            } },
                        React.createElement("img", { className: styles.phoneCall, src: require("../../../../assets/phone-call.svg") }),
                        React.createElement("img", { className: styles.email, src: require("../../../../assets/email.svg") }),
                        React.createElement("img", { className: styles.order, src: require("../../../../assets/order.svg") }),
                        ((_a = data) === null || _a === void 0 ? void 0 : _a.segment) && data.segment in Segments && (React.createElement("img", { className: styles.order, src: require("../../../../assets/star.svg") })))),
                React.createElement("div", { style: { margin: " 0 1rem" } }),
                React.createElement("div", { className: styles.contentWrapper },
                    React.createElement("div", { className: styles.infoWrapper, style: area && government && country
                            ? { padding: 0 }
                            : { padding: "1.1rem 0 0" } },
                        React.createElement("span", { className: styles.name }, CustomerInfo.customer.name || ""),
                        React.createElement("span", { className: styles.address }, area && government && country && (React.createElement(React.Fragment, null,
                            area.EnName,
                            " ,",
                            government.EnName,
                            ", ",
                            country.EnName)))),
                    React.createElement("div", { className: styles.userInfoWrapper },
                        React.createElement("span", { className: styles.phoneNumber },
                            CustomerInfo.customer.country_code || "",
                            " ",
                            CustomerInfo.customer.phone_number || ""),
                        React.createElement("span", { className: styles.email }, CustomerInfo.customer.email || ""),
                        React.createElement("span", { className: styles.phone },
                            "Total orders: ",
                            CustomerInfo.total_orders || ""),
                        ((_b = data) === null || _b === void 0 ? void 0 : _b.segment) && data.segment in Segments && (React.createElement("span", { className: styles.phone }, userSegments[data.segment])),
                        React.createElement("a", { target: "_blank", className: styles.openCustomerProfile, href: customerProfileURL() }, "View profile")))),
            orderType === OrderType.delivery && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { marginTop: "2.4rem" } }),
                React.createElement(CustomerAddress, { lat: lat, lng: lng, city_en_name: city_en_name, customer_country: customer_country, address: address, comment: comment, district_ar_name: district_ar_name, district_en_name: district_en_name, sub_district_ar_name: sub_district_ar_name, sub_district_en_name: sub_district_en_name }))))));
};
export default hot(module)(CustomerInformation);
