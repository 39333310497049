import DeliveryOrangeIcon from "../assets/delivery-koinz-orange.svg";
import DeliveryIcon from "../assets/delivery-orange.svg";
import PickupIcon from "../assets/pickup.svg";
import MobileIcon from "../assets/mobile-icon.svg";
import WorldIcon from "../assets/world.svg";
import SpreaderIcon from "../assets/spreader.svg";
import HelpIcon from "../assets/help.svg";
import ClockIcon from "../assets/ic_clock.svg";
import UserIcon from "../assets/ic_user_fill.svg";
import BranchIcon from "../assets/ic_branch.svg";
import AlertIcon from "../assets/ic_alert_triangle.svg";
import OnlinePaymentIcon from "../assets/card.svg";
import ApplePayIcon from "../assets/Apple_Pay-Logo.wine.svg";
import CashIcon from "../assets/feather _ dollar-sign.svg";
import KoinzWalletIcon from "../assets/koinz_wallet.svg";
import KoinzWalletWithOnlinePayment from "../assets/koinz_wallet_online_general.svg";
import KoinzWalletWithApplePay from "../assets/koinz_wallet_online_apple_pay.svg";
import DriveThruIcon from "../assets/drive-thru.svg";
export default {
    DeliveryOrangeIcon: DeliveryOrangeIcon,
    DeliveryIcon: DeliveryIcon,
    PickupIcon: PickupIcon,
    MobileIcon: MobileIcon,
    WorldIcon: WorldIcon,
    SpreaderIcon: SpreaderIcon,
    HelpIcon: HelpIcon,
    ClockIcon: ClockIcon,
    UserIcon: UserIcon,
    BranchIcon: BranchIcon,
    AlertIcon: AlertIcon,
    OnlinePaymentIcon: OnlinePaymentIcon,
    ApplePayIcon: ApplePayIcon,
    CashIcon: CashIcon,
    KoinzWalletIcon: KoinzWalletIcon,
    KoinzWalletWithOnlinePayment: KoinzWalletWithOnlinePayment,
    KoinzWalletWithApplePay: KoinzWalletWithApplePay,
    DriveThruIcon: DriveThruIcon,
};
