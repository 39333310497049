export var OrderType;
(function (OrderType) {
    OrderType["pickup"] = "pickup";
    OrderType["drive_thru"] = "drive_thru";
    OrderType["delivery"] = "delivery";
    OrderType["offline_visit"] = "offline_visit";
    OrderType["offline_redemption"] = "offline_redemption";
})(OrderType || (OrderType = {}));
export var RoutingMethodPOS;
(function (RoutingMethodPOS) {
    RoutingMethodPOS["call_center"] = "call_center";
    RoutingMethodPOS["standalone"] = "standalone";
    RoutingMethodPOS["pos"] = "pos";
})(RoutingMethodPOS || (RoutingMethodPOS = {}));
export var OrderingSource;
(function (OrderingSource) {
    OrderingSource["ordering_portal"] = "ordering_portal";
    OrderingSource["customer_app"] = "customer_app";
})(OrderingSource || (OrderingSource = {}));
export var DeliveryMethods;
(function (DeliveryMethods) {
    DeliveryMethods["third_party_delivery"] = "third_party_delivery";
    DeliveryMethods["in_house_delivery"] = "in_house_delivery";
})(DeliveryMethods || (DeliveryMethods = {}));
export var DMSOrderStatus;
(function (DMSOrderStatus) {
    DMSOrderStatus["rejected"] = "rejected";
    DMSOrderStatus["submitted"] = "submitted";
    DMSOrderStatus["assigning_driver"] = "assigning_driver";
    DMSOrderStatus["courier_assigned"] = "courier_assigned";
    DMSOrderStatus["courier_picking_up"] = "courier_picking_up";
    DMSOrderStatus["courier_delivering"] = "courier_delivering";
    DMSOrderStatus["delivered"] = "delivered";
    DMSOrderStatus["canceled"] = "canceled";
    DMSOrderStatus["cancelled"] = "cancelled";
    DMSOrderStatus["pending"] = "pending";
})(DMSOrderStatus || (DMSOrderStatus = {}));
export var TransactionState;
(function (TransactionState) {
    TransactionState["created"] = "created";
    TransactionState["authorized"] = "authorized";
    TransactionState["refunded_to_wallet"] = "refunded_to_wallet";
    TransactionState["voided"] = "voided";
    TransactionState["captured"] = "captured";
    TransactionState["refunded"] = "refunded";
    TransactionState["pending"] = "pending";
    TransactionState["failed"] = "failed";
    TransactionState["authenticated"] = "authenticated";
})(TransactionState || (TransactionState = {}));
// export interface IBranches {
//   branches: IBranch[];
//   hasMore: boolean;
//   filters: BranchesFilter;
//   page: number;
// }
export var AvailabilityStatus;
(function (AvailabilityStatus) {
    AvailabilityStatus["disconnected"] = "disconnected";
    AvailabilityStatus["manual"] = "manual";
    AvailabilityStatus["service_enabled"] = "service_enabled";
})(AvailabilityStatus || (AvailabilityStatus = {}));
export var ISBlocked;
(function (ISBlocked) {
    ISBlocked[ISBlocked["false"] = 0] = "false";
    ISBlocked[ISBlocked["true"] = 1] = "true";
})(ISBlocked || (ISBlocked = {}));
export var OrderPlatform;
(function (OrderPlatform) {
    OrderPlatform["app"] = "app";
    OrderPlatform["webportal"] = "webportal";
})(OrderPlatform || (OrderPlatform = {}));
export var compensationTicketType;
(function (compensationTicketType) {
    compensationTicketType["koinzPromos"] = "koinz-promos";
    compensationTicketType["koinzPoints"] = "koinz-points";
})(compensationTicketType || (compensationTicketType = {}));
